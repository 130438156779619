import { Module, VuexModule, Action } from "vuex-module-decorators";
import { getApiClient } from "@/services/api";
import Store from "../index";

// This module is only for functionality related to the following endpoint:
const endpoint = "log";

@Module({
    dynamic: true,
    store: Store,
    name: "LogModule",
    namespaced: true
})
export default class LogModule extends VuexModule {
    @Action({ rawError: true })
    appendLogEntry(logEntry: object) {
        const token = this.context.rootGetters.idToken;
        const isProduction = this.context.rootGetters.isProduction;

        return new Promise((resolve, reject) => {
            if (!isProduction) {
                return resolve("logging is only for production");
            }

            getApiClient()
                .post(`/${endpoint}/append`, logEntry, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ rawError: true })
    appendLogEntryTest(logEntry: object) {
        const token = this.context.rootGetters.idToken;
        const isProduction = this.context.rootGetters.isProduction;
        console.log("log entry test");
        console.log(logEntry);

        return new Promise((resolve, reject) => {
            if (isProduction) {
                return resolve("test logging is only for development");
            }

            getApiClient()
                .post(`/${endpoint}/append`, logEntry, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }
}
