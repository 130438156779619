<template>
    <div id="app">
        <HandleServerTime />

        <transition name="page-fade" mode="out-in">
            <HandleAppUpdates />
        </transition>

        <transition name="page-fade" mode="out-in">
            <site-header v-if="enableHeader" />
        </transition>

        <main class="site-content">
            <transition name="page-fade" mode="out-in">
                <router-view :key="route.params.id || route.params.name" />
            </transition>
        </main>

        <transition name="page-fade" mode="out-in">
            <site-footer v-if="enableFooter" />
        </transition>

        <chat-helpdesk
            v-if="!chatNotAllowedInRoute && isChatEnabled"
        ></chat-helpdesk>

        <session-evaluation v-if="sessionEvalsEnabled" />
    </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import screenfull from "screenfull";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import ChatHelpdesk from "@/components/ChatHelpdesk";
import HandleServerTime from "@/components/HandleServerTime";
import HandleAppUpdates from "@/components/HandleAppUpdates";
import eventHub from "@/event-hub";
import SessionEvaluation from "@/components/SessionEvaluation";

export default {
    name: "Home",
    components: {
        "site-header": Header,
        "site-footer": Footer,
        ChatHelpdesk,
        HandleServerTime,
        HandleAppUpdates,
        SessionEvaluation
    },
    computed: {
        ...mapState([
            "enableHeader",
            "enableFooter",
            "route",
            "themeConfig",
            "authWantsRoute"
        ]),
        ...mapGetters(["isChatEnabled", "favicon", "getPageOptions"]),
        chatNotAllowedInRoute() {
            // TODO this should be part of the `isChatEnabled` logic but tyescript is being problematic
            const disableOnRoutes = ["MeetingView", "MeetingDeveloper"];

            return Boolean(disableOnRoutes.includes(this.route.name));
        },
        sessionEvalsEnabled() {
            const pageOptions = this.getPageOptions("sessionEvals");

            return pageOptions && "enabled" in pageOptions
                ? pageOptions.enabled
                : true;
        }
    },
    watch: {
        authWantsRoute() {
            const route = this.authWantsRoute;
            this.goToRouteAuthWants(route);
        }
    },
    created() {
        this.listenForEscape();

        /**
         * NOTE about quering API data in this component.
         */
        // Step 1. Try retrieving api data when app loads.
        this.handleMeetingSettings();

        // Step 2. There is a bug where the app may load and someone is logged in but the api initially rejects the request.
        // In those cases `Step 1` will fail. So we will listen to an `eventHub` event and retry our request if needed.
        eventHub.$on("request-meeting-settings", this.handleMeetingSettings);

        if (this.favicon) {
            // TODO
            // Use SVG
            // https://css-tricks.com/svg-favicons-and-all-the-fun-things-we-can-do-with-them/
            // ... and we should really be using enviornment variables
            // this current setup is temporary and will likely break the favicon on some devices.
            document.querySelectorAll('link[rel="icon"]').forEach((item) => {
                if (this.favicon.includes(".svg")) {
                    item.type = "image/svg+xml";
                }
                item.href = `${process.env.BASE_URL}favicon/${this.favicon}`;
            });
        }

        if (screenfull.isEnabled) {
            screenfull.on("change", () => {
                this.setIsFullScreen();
            });
        }
    },
    beforeDestroy() {
        eventHub.$off("request-meeting-settings", this.handleMeetingSettings);
    },
    methods: {
        ...mapMutations(["setIsFullScreen"]),

        ...mapActions(["getAndSetMeetingSettings", "getUserinfo"]),
        handleMeetingSettings() {
            this.getUserinfo().then(() => {
                eventHub.$emit("getUserinfo-loaded");
            });
            this.getAndSetMeetingSettings();
            this.handleOneSignal();
        },
        listenForEscape() {
            const body = document.querySelector("body");
            body.onkeydown = function(e) {
                if (e.keyCode != 27) return;
                eventHub.$emit("key-pressed-escape");
            };
        },
        handleOneSignal() {
            const settings = this.themeConfig.oneSignalSettings;
            const oneSignalId = settings && settings.id ? settings.id : "";
            const oneSignalHost =
                settings && settings.hostname ? settings.hostname : "";
            // TODO: we will just need to find a way to put this on config
            if (
                window.OneSignal &&
                oneSignalId != "" &&
                window.location.hostname === oneSignalHost
            ) {
                window.OneSignal.push(function() {
                    window.OneSignal.init({
                        appId: oneSignalId
                    });
                });
            }
        },
        goToRouteAuthWants(route) {
            const to = route ? route : "/";
            this.$router.push(to);
        }
    }
};
</script>
