import {
    config,
    Module,
    VuexModule,
    Mutation,
    Action
} from "vuex-module-decorators";
import Store from "../index";
import apiSvc from "../../services/api";
import { getApiClient } from "../../services/api";

import {
    SessionDataObject,
    SessionOptions,
    SingleSessionOptions,
    MasterSessionObject
} from "@/types/interfaces";

// Set rawError to true by default on all @Action decorators
config.rawError = true;

const endpoint = "sessions";

@Module({
    dynamic: true,
    store: Store,
    name: "getSessionData",
    namespaced: true
})
export default class GetSessionsModule extends VuexModule {
    sessionData: MasterSessionObject = {
        onDemand: [],
        upcomingSessions: [],
        currentTimeISO: ""
    };

    singleSession: SessionDataObject = {
        isSponsorVideo: false,
        image: "",
        videoUrl: "",
        videoUrlOnDemand: "",
        sessionId: "",
        date: "",
        description: "",
        startTime: "",
        endTime: "",
        speakers: [
            {
                biography: "",
                title: "",
                name: ""
            }
        ],
        title: "",
        currentTimeISO: "",
        onDemandOnly: false,
        notForOnDemand: false,
        speakeasyType: "",
        document: "",
        notForPromo: false
    };

    sessionUpdataPayload: SessionDataObject | Partial<SessionDataObject> = {};

    get sessionOptions() {
        const options = this.context.rootState?.themeConfig?.sessionOptions;
        return options || {};
    }

    get session() {
        return this.singleSession;
    }

    get sessionOnDemand() {
        if (this.sessionData) {
            return this.sessionData.onDemand
                .map((session) => {
                    return { ...session, isExpanded: false };
                })
                .filter((item) => {
                    return Boolean(!item.notForOnDemand);
                });
        } else {
            return [];
        }
    }

    get sessionPromos() {
        if (this.sessionData.onDemand) {
            return this.sessionData.onDemand
                .map((session) => {
                    return { ...session, isExpanded: false };
                })
                .filter((item) => {
                    return Boolean(!item.notForPromo);
                });
        } else {
            return [];
        }
    }

    @Mutation
    public setSessions(data: MasterSessionObject) {
        this.sessionData = data;
    }

    @Mutation
    public setSessionPayload(data: SessionDataObject) {
        this.sessionUpdataPayload = data;
    }

    @Mutation setSingleSession(data: SessionDataObject) {
        this.singleSession = data;
    }

    @Action({ commit: "setSessions" })
    getSessionData(options: SessionOptions) {
        const tableName = options.isDemoData
            ? "demo-sessions"
            : options.tableName;

        return new Promise((resolve, reject) => {
            apiSvc
                .getSessions(tableName)
                .then((response) => {
                    /**
                     * TODO this is temprary
                     * Delete this block of code once this jira item is done:
                     * https://matrix-group.atlassian.net/browse/VMP-1786
                     */
                    const upcomingSessions = response.data.upcomingSessions;
                    if (Array.isArray(response.data.upcomingSessions)) {
                        response.data.upcomingSessions = upcomingSessions.filter(
                            (item: SessionDataObject) => {
                                return Boolean(!item.onDemandOnly);
                            }
                        );
                    }
                    /**
                     * End temporary code.
                     */

                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ commit: "setSingleSession" })
    getSingleSession(options: SingleSessionOptions) {
        const tableName = options.isDemoData
            ? "demo-sessions"
            : options.tableName;

        return new Promise((resolve, reject) => {
            apiSvc
                .getSession(options.sessionId, tableName)
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ rawError: true })
    getSession(sessionId: string) {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`/${endpoint}/${sessionId}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ rawError: true })
    editSession() {
        const payload = this.sessionUpdataPayload;
        const { sessionId, ...rest } = payload;
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .put(`/${endpoint}/${sessionId}`, rest, {
                    headers: { Authorization: `bearer ${token}` }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ rawError: true })
    getSpeakeasyAttendee(sessionId: string) {
        const id = sessionId;
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`/${endpoint}/${id}?attendees=true`, {
                    headers: { Authorization: `bearer ${token}` }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }
}
