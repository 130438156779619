var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-splash home-container ",class:{ 'has-animated-bg': _vm.pageOptions.hasAnimatedBackground }},[_c('section',{staticClass:"hero flex justify-center gate-bg",class:[_vm.boxPositioned],style:(_vm.screenSize >= '1024'
                    ? 'background:url(' + _vm.splashImage + ')'
                    : '')},[(
                    _vm.pageOptions.splashGateText &&
                        _vm.pageOptions.splashGateText.isActive
                )?_c('span',{staticClass:"hidden md:block",class:_vm.pageOptions.splashGateText.classToLoad,domProps:{"innerHTML":_vm._s(_vm.pageOptions.splashGateText.textContent)}}):_vm._e(),(_vm.pageOptions.animatedBackgroundName == 'fact')?_c('fact-animated-bg'):(
                    _vm.pageOptions.hasAnimatedBackground &&
                        _vm.pageOptions.animatedBackgroundPath
                )?_c('iframe',{staticClass:"w-screen h-screen fixed overflow-hidden",attrs:{"scrolling":"no","src":_vm.pageOptions.assetsHost + _vm.pageOptions.animatedBackgroundPath}}):(_vm.splashImage == '/splash/')?_c('div',{staticClass:"mask"}):_vm._e(),(_vm.boxPositioned)?_c('div',{staticClass:"entry-card text-center relative",class:[_vm.entryCardClass, _vm.marginPositioned]},[_c('div',{staticClass:"flex flex-col items-center mb-10 w-full"},[(_vm.pageOptions.videoBackground)?_c('div',{staticClass:"w-full"},[_c('video-component',{attrs:{"options":{
                                autoplay: true,
                                loop: true,
                                controls: false,
                                muted: true,
                                sources: [
                                    {
                                        src: _vm.pageOptions.videoBackground,
                                        type: 'application/x-mpegURL',
                                        https: ''
                                    }
                                ]
                            }}})],1):(_vm.pageOptions.animatedSvg)?_c('object',{staticClass:"name-logo mb-4 w-full md:w-1/2",attrs:{"type":"image/svg+xml","data":_vm.pageOptions.animatedSvg,"aria-hidden":"true"}}):(_vm.logoPath)?_c('img',{staticClass:"name-logo",attrs:{"src":_vm.logoPath,"alt":"","aria-hidden":"true"}}):_vm._e(),(_vm.slogan)?_c('h3',{staticClass:"mb-0 mt-6 lg:px-0 slogan-text leading-none",class:_vm.pageOptions.sloganFontSize
                                ? _vm.pageOptions.sloganFontSize
                                : 'text-3xl'},[_vm._v(" "+_vm._s(_vm.slogan)+" ")]):_vm._e()]),(_vm.conferenceName && _vm.pageOptions.displayConferenceName)?_c('div',{staticClass:"w-full text-center",class:!_vm.pageOptions.disableCardUI ? 'max-w-md' : ''},[_c('h1',{staticClass:"conference-name title text-lg font-heading",class:_vm.pageOptions.splashGateText.isActive
                                ? 'lg:text-xl'
                                : 'lg:text-3xl'},[_vm._v(" "+_vm._s(_vm.conferenceName)+" ")])]):_vm._e(),(_vm.conferenceYear)?_c('h2',{staticClass:"conference-year text-lg font-semibold"},[_vm._v(" "+_vm._s(_vm.conferenceYear)+" ")]):(
                        _vm.displaySlogan && !_vm.pageOptions.displayConferenceName
                    )?_c('div',{staticClass:"w-full text-center",class:!_vm.pageOptions.disableCardUI ? 'max-w-md' : ''},[_c('h3',{staticClass:"conference-year title  mb-0 text-lg font-heading",class:_vm.pageOptions.splashGateText.isActive
                                ? 'lg:text-xl'
                                : 'lg:text-3xl'},[_vm._v(" "+_vm._s(_vm.displaySlogan)+" ")])]):_vm._e(),(_vm.isAuthenticated && !_vm.isAuthorized)?_c('b-notification',{staticClass:"my-6 text-left",attrs:{"type":"is-danger","has-icon":"","closable":false}},[_vm._v(" This site is only available to registered attendees. ")]):_vm._e(),(!_vm.isSiteActive)?_c('b-notification',{key:"meeting-has-ended",staticClass:"my-6 text-left",attrs:{"type":"is-info","has-icon":"","closable":false}},[_vm._v(" This meeting has ended. ")]):_c('div',{key:"meeting-is-active",staticClass:"my-6 flex flex-col items-center"},[(_vm.isAuthenticated && !_vm.isAuthorized)?_c('button',{staticClass:"button is-primary  pill-button pill-button is-size-4 pt-1",class:[_vm.gatePageButton],on:{"click":_vm.logoutUser}},[_vm._v(" "+_vm._s(_vm.buttonTexts.logout)+" ")]):_c('button',{staticClass:"button  pill-button text-lg",class:[_vm.gatePageButton],on:{"click":_vm.goToMeeting}},[_vm._v(" "+_vm._s(_vm.pageOptions.buttonPlaceholder || _vm.buttonTexts.enter)+" ")]),(_vm.hostLogoGate)?_c('div',{staticClass:"w-3/4 flex items-center justify-center"},[_c('img',{attrs:{"src":_vm.hostLogoGate,"alt":""}})]):_vm._e()]),(_vm.loginError)?_c('p',{staticClass:"is-error my-4"},[_vm._v(_vm._s(_vm.loginError))]):_vm._e(),_vm._m(0)],1):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"my-4"},[_vm._v(" Virtual meeting by "),_c('a',{staticClass:"has-text-weight-semibold hover:text-accent-alt",attrs:{"href":"https://www.matrixgroup.net/BeSpeake","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("BeSpeake")]),_c('sup',{staticClass:"text-xl"},[_vm._v("®")])])}]

export { render, staticRenderFns }