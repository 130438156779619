





















































































import { Component, Vue, Watch } from "vue-property-decorator";
import { ActiveNavigation } from "@/types/interfaces";

@Component
export default class NavBar extends Vue {
    networkingNotificationsInterval = -1;
    open = false;
    headeroffsetHeight = "";

    /**
     * Computed
     */

    get navRoutes(): object {
        const allRoutes = Vue.prototype
            .MgSortByProperty(this.$store.getters.activeNavigation, "order")
            .filter((obj: ActiveNavigation) => {
                return obj.active;
            });

        return allRoutes;
    }

    get headerOptions(): boolean {
        return this.$store.getters.layoutOptions?.headerOptions || {};
    }

    get conferenceYear(): number {
        return this.$store.getters.conferenceYear;
    }

    get conferenceTitle(): string {
        return this.$store.getters.conferenceName;
    }

    get talkUnreads() {
        return this.$store.getters.talkUnreads;
    }

    get networkingUnreads() {
        return this.$store.getters.networkingNotificationsUnseen;
    }

    get route() {
        return this.$store.state.route;
    }

    /**
     * Watchers
     */
    @Watch("route")
    routeChanged() {
        if ("scheduleComponent" === this.route.name) {
            window.clearInterval(this.networkingNotificationsInterval);
        } else {
            this.handleNetworkingNotificationsInterval();
        }

        /**
         * need to watch active state for nav bar when it is "/" on this route
         as nav bar item seems ignoring empty routes
        **/
        const eL = document.querySelector("a#Home");
        if (eL) {
            if (this.route.name === "Home") {
                eL!.classList.add("router-link-active");
            } else {
                eL!.classList.remove("router-link-active");
            }
        }
    }

    /**
     * Lifecycle
     */
    created() {
        this.$store.dispatch("getNetworkingNotifications");

        this.handleNetworkingNotificationsInterval();
    }

    beforeDestroy() {
        window.clearInterval(this.networkingNotificationsInterval);
    }

    /**
     * Methods
     */

    handleNetworkingNotificationsInterval() {
        window.clearInterval(this.networkingNotificationsInterval);

        const ONE_SECOND = 1000;
        this.networkingNotificationsInterval = window.setInterval(() => {
            this.$store.dispatch("getNetworkingNotifications");
        }, ONE_SECOND * 30);
    }

    openNav() {
        // document.getElementById("navSidePanel")!.style.width = "250px";
        this.open = !this.open;
    }

    closeNav() {
        // document.getElementById("navSidePanel")!.style.width = "0";
        this.open = false;
    }

    handleNavicon(event: Event, targetSelector: string): void {
        const el = event.target as HTMLAnchorElement;
        const nodeTarget = document.getElementById(targetSelector);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        if (el) {
            el.classList.toggle("is-active");
        }

        if (nodeTarget) {
            nodeTarget.classList.toggle("is-active");
        }
    }

    shouldShowUnread(routePath = "") {
        const case1 =
            Boolean("/message-center" === routePath) &&
            Boolean(this.getUnread("/message-center") > 0);
        const case2 =
            "/schedule" === routePath &&
            Boolean(this.getUnread("/schedule") > 0);

        return case1 || case2;
    }

    getUnread(routePath = "") {
        let returnValue = 0;

        if ("/message-center" === routePath) {
            returnValue = this.talkUnreads;
        } else if ("/schedule" === routePath) {
            returnValue = this.networkingUnreads;
        }

        return returnValue;
    }
}
