

























































import NavBar from "@/components/header/navbar.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
        NavBar
    }
})
export default class SiteHeader extends Vue {
    isOpen = false;
    nav = "";
    bg = "";
    color = "";
    publicPath = process.env.BASE_URL;
    isReady = false;

    get headerOptions(): boolean {
        return this.$store.getters.layoutOptions?.headerOptions || {};
    }

    get fontTouse(): string {
        const options = this.$store.getters.getPageOptions("header") || {};
        return options.fontToUse;
    }

    get siteLogo(): string {
        return this.$store.getters.logo;
    }

    get mobileLogo(): string {
        return this.$store.getters.mobileLogo;
    }

    get logoDesc(): string {
        return this.$store.getters.logoDesc;
    }

    get decorImage(): string | boolean {
        if (this.$store.getters.decorImage) {
            return this.publicPath + "img/" + this.$store.getters.decorImage;
        }
        return false;
    }

    get navImage(): string | boolean {
        if (this.$store.getters.navImage) {
            return (
                this.publicPath + "nav-images/" + this.$store.getters.navImage
            );
        }
        return false;
    }

    get cssVariables() {
        return this.$store.getters.cssVariables;
    }
}
