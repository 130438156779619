<template>
    <svg
        width="65px"
        height="69px"
        viewBox="0 0 65 69"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <!-- https://dd401jc05x2yk.cloudfront.net/css/ico-test-chat-bubble.svg -->
        <title>Group 7</title>
        <desc>Created with Sketch.</desc>
        <defs>
            <path
                d="M-1.42108547e-14,61.0010159 C-1.42108547e-14,61.5527396 0.334332238,61.7106792 0.755102619,61.3465576 L18.507137,45.984497 C18.9241686,45.6236108 19.7122078,45.2802453 20.2512194,45.2193813 L49.8834561,41.8733769 C52.6267262,41.5636132 54.9669099,39.0767126 55.1103903,36.3189676 L56.7401956,4.99353239 C56.8836816,2.2356806 54.7655324,1.49213975e-13 51.9915869,1.49213975e-13 L5.00841312,1.49213975e-13 C2.24234293,1.49213975e-13 -1.42108547e-14,2.243241 -1.42108547e-14,4.99502992 L-1.42108547e-14,61.0010159 Z"
                :id="`${_uid}-chat-connect-path`"
            ></path>
            <filter
                x="-12.3%"
                y="-8.1%"
                width="124.7%"
                height="122.7%"
                filterUnits="objectBoundingBox"
                id="chat-connect-filter"
            >
                <feOffset
                    dx="0"
                    dy="2"
                    in="SourceAlpha"
                    result="shadowOffsetOuter1"
                ></feOffset>
                <feGaussianBlur
                    stdDeviation="2"
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                ></feGaussianBlur>
                <feColorMatrix
                    values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
                    type="matrix"
                    in="shadowBlurOuter1"
                ></feColorMatrix>
            </filter>
        </defs>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-822.000000, -1006.000000)">
                <g transform="translate(645.000000, 909.000000)">
                    <g transform="translate(181.100727, 99.000000)">
                        <g
                            transform="translate(28.373538, 30.771427) scale(-1, 1) translate(-28.373538, -30.771427) "
                        >
                            <use
                                fill="black"
                                fill-opacity="1"
                                filter="url(#chat-connect-filter)"
                                :xlink:href="`#${_uid}-chat-connect-path`"
                            ></use>
                            <use
                                fill="currentColor"
                                fill-rule="evenodd"
                                :xlink:href="`#${_uid}-chat-connect-path`"
                            ></use>
                        </g>
                        <ellipse
                            fill-opacity="0.74"
                            fill="#000000"
                            cx="39.0887499"
                            cy="14.1768706"
                            rx="3.18947719"
                            ry="3.17687056"
                        ></ellipse>
                        <ellipse
                            fill-opacity="0.74"
                            fill="#000000"
                            cx="12.0887499"
                            cy="14.1768706"
                            rx="3.18947719"
                            ry="3.17687056"
                        ></ellipse>
                        <path
                            d="M26.6246534,31.3651317 C33.0124611,31.3651317 36.3431602,23.1529313 36.3431602,23.1529313 C36.8277357,22.1655568 36.3289602,21.3651317 35.2121107,21.3651317 L18.4790128,21.3651317 C17.3697759,21.3651317 16.8134678,22.1834737 17.2861775,23.187658 C17.2861775,23.187658 20.2368457,31.3651317 26.6246534,31.3651317 Z"
                            fill="#FFFFFF"
                        ></path>
                        <path
                            d="M32.0276907,28.8647216 C32.0276907,26.9921423 28.7104595,25.4755859 26.481426,25.4755859 C24.2729838,25.4755859 20.9730032,26.7716034 20.9730032,28.4203711 C20.9730032,29.4532745 24.6097355,31.372889 26.6246534,31.3651258 C28.6095095,31.3574785 32.0276907,29.5703456 32.0276907,28.8647216 Z"
                            fill-opacity="0.371612762"
                            fill="#F78787"
                        ></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: "svg-chat-connect"
};
</script>
