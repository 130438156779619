const options = {
    root: null,
    rootMargin: "0px 0px 100px 0px",
    threshold: 0
};
const oberver = new IntersectionObserver((entries, oberver) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            const lazyTarget = entry.target as HTMLImageElement;
            lazyTarget.src = lazyTarget.dataset.src || "";
            oberver.unobserve(entry.target);
        } else {
            return;
        }
    });
}, options);

export default {
    bind(el: HTMLElement) {
        oberver.observe(el);
    }
};
