import Vue from "vue";
import axios from "axios";
import linkifyHtml from "linkifyjs/html";
import store from "../store";

export const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE
});

axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";

export const getApiClient = () =>
    axios.create({
        baseURL: store.getters.apiConfig?.baseUrl,
        withCredentials: false,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    });

export default {
    getServerTime() {
        const token = store.state.userCredentials.idToken;
        return getApiClient().get(`/server-time`, {
            headers: {
                Authorization: `bearer ${token}`
            }
        });
    },
    getTradeshowCompanies(tableName = "") {
        const token = store.state.userCredentials.idToken;
        if (tableName) {
            return getApiClient().get(`/tradeshow?tableName=${tableName}`, {
                headers: {
                    Authorization: `bearer ${token}`
                }
            });
        } else {
            return getApiClient().get(`/tradeshow`, {
                headers: {
                    Authorization: `bearer ${token}`
                }
            });
        }
    },
    getSession(sessionId: string, tableName = "") {
        const token = store.state.userCredentials.idToken;
        if (tableName) {
            return getApiClient().get(
                `/sessions/${sessionId}?tableName=${tableName}`,
                {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                }
            );
        } else {
            return getApiClient().get(`/sessions/${sessionId}`, {
                headers: {
                    Authorization: `bearer ${token}`
                }
            });
        }
    },
    getSessions(tableName = "") {
        const token = store.state.userCredentials.idToken;
        if (tableName) {
            return getApiClient().get(`/sessions?tableName=${tableName}`, {
                headers: {
                    Authorization: `bearer ${token}`
                }
            });
        } else {
            return getApiClient().get(`/sessions`, {
                headers: {
                    Authorization: `bearer ${token}`
                }
            });
        }
    },
    getVideoComments(sessionId: string, after = "") {
        // 'after' is an iso string representing a point of time of earliest comments to get
        const token = store.state.userCredentials.idToken;
        return getApiClient().get(`/comments/${sessionId}?after=${after}`, {
            headers: {
                Authorization: `bearer ${token}`
            }
        });
    },
    getFeaturedVideo() {
        const token = store.state.userCredentials.idToken;
        return getApiClient().get("/featured-video", {
            headers: {
                Authorization: `bearer ${token}`
            }
        });
    },
    postVideoComment(sessionId: string, text: string) {
        const token = store.state.userCredentials.idToken;

        text = linkifyHtml(text, {
            attributes: {
                rel: "noopener noreferrer"
            }
        });

        text = Vue.prototype.MgSanitize(text);

        return getApiClient().post(
            `/comments/${sessionId}`,
            { text },
            {
                headers: {
                    Authorization: `bearer ${token}`
                }
            }
        );
    },
    createUrl(url: string, starts: number, expires: number) {
        const token = store.state.userCredentials.idToken;
        return getApiClient().post(
            "/video/url",
            {
                url,
                starts,
                expires
            },
            {
                headers: {
                    Authorization: `bearer ${token}`
                }
            }
        );
    },
    createCookies(url: string, starts: number, expires: number) {
        const token = store.state.userCredentials.idToken;
        return getApiClient().post(
            "/video/cookies",
            {
                url,
                starts,
                expires
            },
            {
                headers: {
                    Authorization: `bearer ${token}`
                }
            }
        );
    }
};
