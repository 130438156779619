<template>
    <div
        class="bg-splash home-container "
        :class="{ 'has-animated-bg': pageOptions.hasAnimatedBackground }"
    >
        <section
            class="hero flex justify-center gate-bg"
            :class="[boxPositioned]"
            :style="
                screenSize >= '1024'
                    ? 'background:url(' + splashImage + ')'
                    : ''
            "
        >
            <span
                class="hidden md:block"
                v-if="
                    pageOptions.splashGateText &&
                        pageOptions.splashGateText.isActive
                "
                :class="pageOptions.splashGateText.classToLoad"
                v-html="pageOptions.splashGateText.textContent"
            ></span>
            <!-- 
				TODO 
				fact-animated-bg needs to removed,
				need to use the iframe / animatedBackgroundPath setup as privatebrands does
			-->
            <fact-animated-bg
                v-if="pageOptions.animatedBackgroundName == 'fact'"
            />

            <iframe
                v-else-if="
                    pageOptions.hasAnimatedBackground &&
                        pageOptions.animatedBackgroundPath
                "
                class="w-screen h-screen fixed overflow-hidden"
                scrolling="no"
                :src="
                    pageOptions.assetsHost + pageOptions.animatedBackgroundPath
                "
            ></iframe>

            <div v-else-if="splashImage == '/splash/'" class="mask"></div>

            <div
                v-if="boxPositioned"
                class="entry-card text-center relative"
                :class="[entryCardClass, marginPositioned]"
            >
                <div class="flex flex-col items-center mb-10 w-full">
                    <div v-if="pageOptions.videoBackground" class="w-full">
                        <video-component
                            :options="{
                                autoplay: true,
                                loop: true,
                                controls: false,
                                muted: true,
                                sources: [
                                    {
                                        src: pageOptions.videoBackground,
                                        type: 'application/x-mpegURL',
                                        https: ''
                                    }
                                ]
                            }"
                        />
                    </div>
                    <object
                        v-else-if="pageOptions.animatedSvg"
                        type="image/svg+xml"
                        class="name-logo mb-4 w-full md:w-1/2"
                        :data="pageOptions.animatedSvg"
                        aria-hidden="true"
                    ></object>
                    <img
                        v-else-if="logoPath"
                        class="name-logo"
                        :src="logoPath"
                        alt=""
                        aria-hidden="true"
                    />
                    <h3
                        v-if="slogan"
                        class="mb-0 mt-6 lg:px-0 slogan-text leading-none"
                        :class="
                            pageOptions.sloganFontSize
                                ? pageOptions.sloganFontSize
                                : 'text-3xl'
                        "
                    >
                        {{ slogan }}
                    </h3>
                </div>

                <div
                    v-if="conferenceName && pageOptions.displayConferenceName"
                    class="w-full text-center"
                    :class="!pageOptions.disableCardUI ? 'max-w-md' : ''"
                >
                    <h1
                        class="conference-name title text-lg font-heading"
                        :class="
                            pageOptions.splashGateText.isActive
                                ? 'lg:text-xl'
                                : 'lg:text-3xl'
                        "
                    >
                        {{ conferenceName }}
                    </h1>
                </div>
                <h2
                    v-if="conferenceYear"
                    class="conference-year text-lg font-semibold"
                >
                    {{ conferenceYear }}
                </h2>
                <!-- TODO: When we use other statement to be display in gate rather than the conference name  -->
                <div
                    v-else-if="
                        displaySlogan && !pageOptions.displayConferenceName
                    "
                    class="w-full text-center"
                    :class="!pageOptions.disableCardUI ? 'max-w-md' : ''"
                >
                    <h3
                        class="conference-year title  mb-0 text-lg font-heading"
                        :class="
                            pageOptions.splashGateText.isActive
                                ? 'lg:text-xl'
                                : 'lg:text-3xl'
                        "
                    >
                        {{ displaySlogan }}
                    </h3>
                </div>

                <b-notification
                    v-if="isAuthenticated && !isAuthorized"
                    class="my-6 text-left"
                    type="is-danger"
                    has-icon
                    :closable="false"
                >
                    This site is only available to registered attendees.
                </b-notification>

                <b-notification
                    v-if="!isSiteActive"
                    type="is-info"
                    has-icon
                    :closable="false"
                    class="my-6 text-left"
                    key="meeting-has-ended"
                >
                    This meeting has ended.
                </b-notification>
                <div
                    v-else
                    key="meeting-is-active"
                    class="my-6 flex flex-col items-center"
                >
                    <button
                        v-if="isAuthenticated && !isAuthorized"
                        @click="logoutUser"
                        class="button is-primary  pill-button pill-button is-size-4 pt-1"
                        :class="[gatePageButton]"
                    >
                        {{ buttonTexts.logout }}
                    </button>
                    <button
                        v-else
                        @click="goToMeeting"
                        class="button  pill-button text-lg"
                        :class="[gatePageButton]"
                    >
                        {{ pageOptions.buttonPlaceholder || buttonTexts.enter }}
                    </button>

                    <div
                        v-if="hostLogoGate"
                        class="w-3/4 flex items-center justify-center"
                    >
                        <img :src="hostLogoGate" alt="" />
                    </div>
                </div>

                <p class="is-error my-4" v-if="loginError">{{ loginError }}</p>
                <p class="my-4">
                    Virtual meeting by
                    <a
                        href="https://www.matrixgroup.net/BeSpeake"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="has-text-weight-semibold hover:text-accent-alt"
                        >BeSpeake</a
                    ><sup class="text-xl">&reg;</sup>
                </p>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import { getModule } from "vuex-module-decorators";
import auth from "../services/Auth";
import VideoComponent from "@/components/shared/VideoComponent.vue";
import FactAnimatedBg from "@/components/FACT/FactAnimatedBg.vue";

import logVuexModule from "@/store/vuex-modules/log";
const logStore = getModule(logVuexModule);

export default {
    data() {
        return {
            path: window.location.href,
            publicPath: process.env.BASE_URL,
            loginError: "",
            boxPositioned: "",
            marginPositioned:
                "" /** Default position position when log in box is centered */,
            screenSize: ""
        };
    },
    components: {
        VideoComponent,
        "fact-animated-bg": FactAnimatedBg
    },
    computed: {
        ...mapState(["themeConfig"]),
        ...mapGetters([
            "conferenceName",
            "displaySlogan",
            "conferenceYear",
            "authConfig",
            "isAuthenticated",
            "isAuthorized",
            "gateLogo",
            "logoGate",
            "isSiteActive"
        ]),

        splashImage() {
            if (this.$store.getters.splashImage.S3Url) {
                return this.$store.getters.splashImage.S3Url;
            } else if (this.$store.getters.splashImage.local) {
                return (
                    this.publicPath +
                    "splash/" +
                    this.$store.getters.splashImage.local
                );
            } else {
                return "";
            }
        },
        hostLogoGate() {
            if (this.$store.getters.hostLogoGate) {
                return (
                    this.publicPath + "gate/" + this.$store.getters.hostLogoGate
                );
            }
            return false;
        },
        pageOptions() {
            return this.$store.getters.getPageOptions("gate");
        },

        logoPath() {
            if (this.gateLogo) {
                return this.publicPath + "logos/" + this.gateLogo;
            } else {
                return "";
            }
        },
        slogan() {
            return this.$store.getters.slogan;
        },
        buttonTexts() {
            const text = this.pageOptions.buttonTexts;

            return {
                enter: text && text.enter ? text.enter : "Enter Meeting",
                logout: text && text.logout ? text.logout : "Logout"
            };
        },
        gatePageButton() {
            const getConfig = this.$store.getters.cssVariables;
            return getConfig["--gatePageButton"]
                ? getConfig["--gatePageButton"]
                : "is-primary";
        },
        entryCardClass() {
            const defaultClasses =
                "rounded-xl p-4 md:px-10 py-8 mx-4  w-auto max-w-sm";
            if (this.pageOptions.disableCardUI) {
                return "";
            } else {
                return this.pageOptions?.splashGateText?.isActive
                    ? `${defaultClasses} 'mt-8'`
                    : defaultClasses;
            }
        }
    },
    methods: {
        ...mapActions(["loginUser"]),
        goToMeeting() {
            const authConfig = this.authConfig;

            if (this.isAuthorized) {
                this.$router.push("/");
            } else if (this.themeConfig.forceAuth) {
                auth.getAuthToken(authConfig);
            } else {
                this.$router.push("/");
            }
        },
        logoutUser() {
            this.$store.dispatch("logout");
        },
        createLoginLog() {
            const uid = this.$store.getters.user.id;
            const username = this.$store.getters.user.username;
            const name = this.$store.getters.user.name;

            const logData = {
                type: "LoginUser",
                uid,
                username,
                name
            };

            logStore
                .appendLogEntry(logData)
                .then((res) => console.log(res))
                .catch((err) =>
                    console.log(
                        `Error logging user log in event: ${logData}, ${err}, ${err.stack}`
                    )
                );
        },
        getBoxPositionConfig() {
            switch (this.pageOptions.loginBoxPosition) {
                case "left":
                    this.boxPositioned = "items-start";
                    this.marginPositioned = "left-align";
                    break;
                case "right":
                    this.boxPositioned = "items-center lg:items-end";
                    this.marginPositioned = "right-align";
                    break;
                default:
                    this.boxPositioned = "items-center";
                    this.marginPositioned = "";
            }
        }
    },
    async created() {
        const codeParam = this.$route.query.code;
        if (codeParam && !this.isAuthenticated) {
            try {
                const authConfig = this.authConfig;
                const tokenResponse = await auth.getAccessToken(
                    authConfig,
                    codeParam
                );
                if (!localStorage.getItem("storedPath")) {
                    localStorage.setItem("storedPath", "/");
                }
                await this.loginUser(tokenResponse);
                await this.createLoginLog();
            } catch (err) {
                this.loginError = err || "Login Error";
            }
        }
        this.getBoxPositionConfig();
        this.screenSize = window.screen.width;
    }
};
</script>

<style lang="scss" scoped>
@import "../styles/views/gate.scss";
</style>
