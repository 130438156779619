


































// Base
import { Component, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import eventHub from "@/event-hub";
import { a11yFixBuefyModalAriaAttrs } from "@/services/a11y";

// Types
import { Route } from "vue-router";

// Vuex Modules
import sessionEvalVuexModule from "@/store/vuex-modules/session-evaluations";
import sessionVuexModule from "@/store/vuex-modules/getSessionData";
import logVuexModule from "@/store/vuex-modules/log";
const evalStore = getModule(sessionEvalVuexModule);
const sessionStore = getModule(sessionVuexModule);
const logStore = getModule(logVuexModule);

// SurveyJS setup
import * as SurveyVue from "survey-vue";
const Survey = SurveyVue.Survey;
const defaultQuestions = [
    {
        type: "rating",
        name: "question1",
        title: "Please rate the quality of the CONTENT of the session:",
        hideNumber: true,
        isRequired: true
    },
    {
        type: "rating",
        name: "question2",
        title: "Please rate the quality of the PRESENTATION of the session:",
        hideNumber: true,
        isRequired: true
    },
    {
        type: "radiogroup",
        name: "question3",
        title:
            "Please rate the relevance of the session to you, your job and your company:",
        hideNumber: true,
        isRequired: true,
        choices: [
            "Extremely relevant",
            "Very relevant",
            "Somewhat relevant",
            "Little relevance",
            "Not relevant"
        ]
    },
    {
        type: "comment",
        name: "question4",
        hideNumber: true,
        title: "Any other comments?"
    }
];
SurveyVue.StylesManager.applyTheme("modern");

@Component({
    components: {
        Survey
    },
    directives: {
        addbuefymodal: {
            bind: function(el) {
                eventHub.$emit("BuefyModalBound", el);
            }
        }
    }
})
export default class SessionEvaluation extends Vue {
    loading = false;
    evaluation!: SurveyVue.SurveyModel;
    publicPath = process.env.BASE_URL;
    finishedSurvey = false;

    get sessionData() {
        return sessionStore.session;
    }

    get user() {
        return this.$store.getters.userInfo;
    }

    get isProduction() {
        return this.$store.getters.isProduction;
    }

    get siteLogo(): string {
        return this.$store.getters.logo;
    }

    get sessionName() {
        return evalStore.sessionName;
    }

    get isActive() {
        return evalStore.isActive;
    }

    get activeSessionId() {
        return evalStore.sessionId;
    }

    get surveyObj() {
        return this.evaluation;
    }

    get route() {
        return this.$route;
    }

    get evalOptions() {
        return this.$store.getters.getPageOptions("sessionEvals");
    }

    get questions() {
        return this.evalOptions &&
            Array.isArray(this.evalOptions.questions) &&
            this.evalOptions.questions.length
            ? this.evalOptions.questions
            : defaultQuestions;
    }

    get evalText() {
        const useName =
            this.evalOptions && this.evalOptions.includeSessionNameInHeader;
        const preReplaceHeading =
            this.evalOptions && this.evalOptions.heading
                ? this.evalOptions.heading
                : useName
                ? "Thanks for attending <name>!"
                : "Thanks for attending our session!";
        const postReplaceHeading = useName
            ? preReplaceHeading.replace("<name>", this.sessionName)
            : preReplaceHeading;

        return {
            preEval:
                this.evalOptions && this.evalOptions.preEvalText
                    ? this.evalOptions.preEvalText
                    : "We'd like your feedback so we can improve events in the future.",
            finished:
                this.evalOptions && this.evalOptions.finishedText
                    ? this.evalOptions.finishedText
                    : "Thanks for submitting your feedback!",
            heading: postReplaceHeading
        };
    }

    @Watch("route")
    async maybeOpenModal(to: Route, from: Route) {
        if (from.name === "Session") {
            evalStore.activateSessionEval({
                sessionId: from.params.id,
                sessionName: "our session"
            });
        }
    }

    created() {
        evalStore.getMyResponses();
        this.initializeSurvey();

        eventHub.$on("BuefyModalBound", this.handleBuefyModalFixes);
    }

    beforeDestroy() {
        eventHub.$off("BuefyModalBound", this.handleBuefyModalFixes);
    }

    closeModal() {
        this.finishedSurvey = false;
        this.evaluation.clear(true, true);
        this.evaluation.render();
        evalStore.deactivateSessionEval();
        evalStore.getMyResponses();
    }

    initializeSurvey() {
        const modelInput = {
            pages: [
                {
                    name: "page1",
                    elements: this.questions
                }
            ]
        };
        this.evaluation = new SurveyVue.Model(modelInput);
        this.evaluation.completedHtml =
            "<p>Thanks for submitting your evaluation!</p>";
        this.evaluation.onComplete.add(this.completeSurvey);
    }

    // TODO: Create or discover interface for SurveyJS options to get rid of any type
    completeSurvey(result: SurveyVue.SurveyModel, options: any): any {
        const logData = {
            type: "SessionEvaluation",
            userId: this.user.id,
            userName: this.user.name,
            sessionId: this.activeSessionId,
            sessionName: this.sessionName,
            answers: result.data
        };

        // Unlike other log entries, the polls' log entries actually support the functionality, so we want to log even in dev.
        if (this.isProduction) {
            logStore.appendLogEntry(logData).catch((err) => console.log(err));
        } else {
            logStore
                .appendLogEntryTest(logData)
                .catch((err) => console.log(err));
        }

        this.finishedSurvey = true;
    }

    handleBuefyModalFixes(el: HTMLElement) {
        a11yFixBuefyModalAriaAttrs(el);
    }
}
