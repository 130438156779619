




















































































































import { Component, Vue } from "vue-property-decorator";
import svgTwitter from "@/components/svg/svg-twitter.vue";
import svgLinkedin from "@/components/svg/svg-linkedin.vue";
import svgInstagram from "@/components/svg/svg-instagram.vue";
import svgFeedBurner from "@/components/svg/svg-feedburner.vue";
import svgYoutube from "@/components/svg/svg-youtube.vue";
import svgFacebook from "@/components/svg/svg-facebook.vue";
import svgPinterest from "@/components/svg/svg-pinterest.vue";

interface FooterQuickLink {
    name?: string;
    link?: string;
    displayName?: string;
}

@Component({
    components: {
        "svg-twitter": svgTwitter,
        "svg-likedin": svgLinkedin,
        "svg-instagram": svgInstagram,
        "svg-feedburner": svgFeedBurner,
        "svg-youtube": svgYoutube,
        "svg-facebook": svgFacebook,
        "svg-pinterest": svgPinterest
    }
})
export default class SiteFooter extends Vue {
    publicPath = process.env.BASE_URL;

    get footerContent() {
        return this.$store.getters.getPageOptions("footer");
    }

    get mainFooterContent() {
        return this.footerContent.content?.mainContent
            ? this.footerContent.content.mainContent
            : "";
    }

    get LayoutOptions(): boolean {
        return this.$store.getters.layoutOptions;
    }

    get socialMediaAccount() {
        return this.$store.getters.socialMediaAccount;
    }

    get hasSocialMedia() {
        const keys = Object.keys(this.socialMediaAccount);

        let hasSocial = false;

        keys.forEach((key) => {
            if (
                this.socialMediaAccount[key] &&
                this.socialMediaAccount[key] != ""
            ) {
                hasSocial = true;
            }
        });

        return hasSocial;
    }

    get hasQuickLinks() {
        let hasLinks = false;

        this.footerContent.quickLinks.forEach((item: FooterQuickLink) => {
            if (item.link && item.link != "") {
                hasLinks = true;
            }
        });

        return hasLinks;
    }

    get footerBG(): string {
        const footerImage = this.$store.getters.layoutImages.footer || "";
        const path = `${this.publicPath}footer-images/`;
        let returnValue = "";

        if (footerImage) {
            returnValue = `background-image:url(${path}${footerImage})`;
        }

        return returnValue;
    }

    get footerLogo(): string {
        return this.publicPath + "logos/" + this.$store.getters.footerLogo;
    }

    logout() {
        this.$store.dispatch("logout");
    }
}
