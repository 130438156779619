<template>
    <b-notification
        v-addbuefynotification
        v-if="displayNotification"
        class="fixed top-0 left-0 content mt-4 ml-4 z-50 p-4 shadow"
        type="is-info"
        has-icon
        :closable="false"
    >
        <p>Updates are available.</p>
        <button @click.prevent="refresh" class="button text-sm border-0">
            Get updates
        </button>
    </b-notification>
</template>
<script>
import eventHub from "@/event-hub";
import { a11yFixBuefyNotificationAriaAttrs } from "@/services/a11y";
import axios from "axios";
import { isAfter, isValid } from "date-fns";

export default {
    name: "HandleAppUpdates",
    directives: {
        addbuefynotification: {
            bind: function(el) {
                eventHub.$emit("BuefyNotificationAppUpdatesBound", el);
            }
        }
    },
    data() {
        return {
            refetchInterval: -1,
            updatesAreAvailable: false,
            lastUpdated: new Date()
        };
    },
    computed: {
        route() {
            return this.$store.state.route || {};
        },
        displayNotification() {
            // TODO
            // speakeasies are currently buggy with direct browse
            // so we dont want to encourage page refreshes in speakeasies
            const disableOnRoutes = ["MeetingView"];
            const routeIsAllowed = !disableOnRoutes.includes(this.route.name);

            return this.updatesAreAvailable && routeIsAllowed;
        }
    },
    created() {
        eventHub.$on(
            "BuefyNotificationAppUpdatesBound",
            this.handleBuefyNotificationFixes
        );

        this.checkForUpdates();
        this.handleCheckForUpdates();
    },
    beforeDestroy() {
        eventHub.$off(
            "BuefyNotificationAppUpdatesBound",
            this.handleBuefyNotificationFixes
        );

        window.clearInterval(this.refetchInterval);
    },
    methods: {
        handleCheckForUpdates() {
            const ONE_SECOND = 1000;
            const INTERVAL = ONE_SECOND * 60;
            window.clearInterval(this.refetchInterval);
            this.refetchInterval = window.setInterval(
                this.checkForUpdates,
                INTERVAL
            );
        },
        checkForUpdates() {
            axios.head("/index.html").then((response) => {
                const lastModified = response.headers["last-modified"];
                const lastModifiedDate = new Date(lastModified);
                const lastUpdated = this.lastUpdated;

                if (
                    isValid(lastModifiedDate) &&
                    isAfter(lastModifiedDate, lastUpdated)
                ) {
                    this.updatesAreAvailable = true;
                }
            });
        },
        refresh() {
            window.location.reload();
        },
        handleBuefyNotificationFixes(el) {
            a11yFixBuefyNotificationAriaAttrs(el);
        }
    }
};
</script>
