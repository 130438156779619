/**
 * NOTE don't run `router.push` (and potentially other router functionality) in this file.
 * Doing so causes dynamic vuex modules to not work in some cases (like top level / non-children components).
 */

import Vue from "vue";
import Vuex from "vuex";
import auth from "../services/Auth";
import AWS from "aws-sdk/global";
import screenfull from "screenfull";
import { PageOptionObject, ActiveNavigation } from "@/types/interfaces";

/**
 * Modules
 * please create a module for every API endpoint introduced.
 * modules are in ./vuex-modules/
 */
import settingsVuexModule from "@/store/vuex-modules/settings";
import userinfoVuexModule from "@/store/vuex-modules/userinfo";
import notificationsVuexModule from "@/store/vuex-modules/notifications";
import talkVuexModule from "@/store/vuex-modules/talk";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        settingsVuexModule,
        notificationsVuexModule,
        userinfoVuexModule,
        talkVuexModule
    },
    state: {
        authWantsRoute: "",
        isFullScreen: false,
        enableHeader: true,
        enableFooter: true,
        themeConfigLoaded: false,
        themeConfig: {
            isSiteActive: {
                development: true,
                production: true
            },
            sessionOptions: {},
            ads: "",
            favicon: "",
            logo: "",
            logoGate: "",
            slogan: "",
            logoMeetingView: "",
            footerLogo: "",
            hostLogoGate: "",
            mobileLogo: "",
            bgImage: "",
            navImage: "",
            decorImage: "",
            gateLogo: "",
            logoDescription: "",
            splashImage: "",
            conferenceName: "",
            displaySlogan: "",
            conferenceYear: "",
            conferenceId: "",
            layoutOptions: "",
            layoutImages: "",
            activeNavigation: "",
            fontConfig: "",
            homeLabel: "",
            sessionLabel: "",
            attendeeLabels: "",
            tradeshowLabel: "",
            schedulePageLabel: "",
            messagePageLabel: "",
            usesLocalDataSearch: "",
            socialMediaAccount: "",
            fontFamily: "",
            webfontSrc: "",
            cssVariables: "",
            customLayout: "",
            pageOptions: [],
            primaryInterests: [],
            staticCommunityExchange: [],
            staticExpectations: [],
            forceAuth: false,
            api: {
                development: {},
                production: {}
            },
            authConfig: {
                development: {},
                production: {}
            },
            awsConfig: {},
            helpdeskConnection: {
                region: "",
                api: "",
                instanceId: "",
                contactFlowId: ""
            },
            sponsorVideos: [],
            bannerAds: []
        },
        user: {
            id: "",
            username: "",
            name: "",
            companyName: "",
            email: ""
        },
        userCredentials: {
            idToken: "",
            accessToken: "",
            refreshToken: "",
            expires: 0,
            isRefreshing: false
        },
        exhibitorSearchResults: []
    },
    getters: {
        isProduction: () => {
            return Boolean("production" === process.env.NODE_ENV);
        },

        isSiteActive: (state) => {
            let returnValue = true;
            const option = state.themeConfig?.isSiteActive || {};

            if (Object.keys(option).length) {
                const x = process.env.NODE_ENV as string;
                returnValue = option[x];
            }

            return Boolean(returnValue);
        },

        isPageOptionActiveInEnv: (state, getters) => (
            page: string,
            option: string
        ) => {
            let returnValue = true;
            const options = getters.getPageOptions(page) || {};
            const optionToCheck = options[option];

            if (optionToCheck && Object.keys(optionToCheck).length) {
                const x = process.env.NODE_ENV as string;
                returnValue = optionToCheck[x];
            }

            return Boolean(returnValue);
        },

        ads: (state) => {
            return state.themeConfig.ads;
        },

        helpdeskInstanceRegion: (state) => {
            return state.themeConfig.helpdeskConnection.region;
        },

        helpdeskInstanceApi: (state) => {
            return state.themeConfig.helpdeskConnection.api;
        },

        helpdeskInstanceId: (state) => {
            return state.themeConfig.helpdeskConnection.instanceId;
        },

        helpdeskContactFlowId: (state) => {
            return state.themeConfig.helpdeskConnection.contactFlowId;
        },

        isChatEnabled: (state) => {
            const connectionData = state.themeConfig.helpdeskConnection;

            for (const [key, value] of Object.entries(connectionData)) {
                key; //ts complaint

                if (!value) {
                    return false;
                }
            }

            return true;
        },

        usesLocalDataSearch: (state) => {
            return state.themeConfig.usesLocalDataSearch;
        },

        layoutImages: (state) => {
            return state.themeConfig.layoutImages;
        },

        hostLogoGate: (state) => {
            return state.themeConfig.hostLogoGate;
        },
        socialMediaAccount: (state) => {
            return state.themeConfig.socialMediaAccount;
        },

        layoutOptions: (state) => {
            return state.themeConfig.layoutOptions || {};
        },

        sponsorVideos: (state) => {
            return state.themeConfig.sponsorVideos;
        },

        homeLabel: (state) => {
            return state.themeConfig.homeLabel;
        },

        sessionLabel: (state) => {
            return state.themeConfig.sessionLabel;
        },

        tradeshowLabel: (state) => {
            return state.themeConfig.tradeshowLabel;
        },

        attendeeLabels: (state) => {
            return state.themeConfig.attendeeLabels;
        },

        messagePageLabel: (state) => {
            return state.themeConfig.messagePageLabel;
        },

        schedulePageLabel: (state) => {
            return state.themeConfig.schedulePageLabel;
        },

        fontConfig: (state) => {
            return state.themeConfig.fontConfig;
        },

        cssVariables: (state) => {
            return state.themeConfig.cssVariables;
        },
        getstaticCommunityExchange: (state) => {
            return state.themeConfig.staticCommunityExchange;
        },
        getstaticExpectations: (state) => {
            return state.themeConfig.staticExpectations;
        },

        getPageOptions: (state) => (page: string) => {
            const result = state.themeConfig.pageOptions.find(
                (item: PageOptionObject) => page === item.page
            );
            return result;
        },
        getSpecialInterest: (state) => {
            return state.themeConfig.primaryInterests;
        },
        getPrimaryInterests: (state) => (interest: string) => {
            return state.themeConfig.primaryInterests.find(
                (item: { imageSrc: string; name: string }) =>
                    item.name === interest
            );
        },

        activeNavigation: (state) => {
            return state.themeConfig.activeNavigation;
        },
        getConfigNavItem: (state, getters) => (page: string) => {
            return getters.activeNavigation.find(
                (item: ActiveNavigation) => page === item.name
            );
        },
        slogan: (state) => {
            return state.themeConfig.slogan;
        },
        logo: (state) => {
            let returnValue = "";
            if (state.themeConfig.logo) {
                returnValue = `${process.env.BASE_URL}logos/${state.themeConfig.logo}`;
            }
            return returnValue;
        },
        logoMeetingView: (state) => {
            let returnValue = "";
            if (state.themeConfig.logoMeetingView) {
                returnValue = `${process.env.BASE_URL}logos/${state.themeConfig.logoMeetingView}`;
            }
            return returnValue;
        },
        getBackgroundImage: (state) => {
            return state.themeConfig.bgImage;
        },
        logoGate: (state) => {
            return state.themeConfig.logoGate;
        },
        navImage: (state) => {
            return state.themeConfig.navImage;
        },
        favicon: (state) => {
            return state.themeConfig.favicon;
        },
        footerLogo: (state) => {
            return state.themeConfig.footerLogo;
        },
        mobileLogo: (state) => {
            return state.themeConfig.mobileLogo;
        },
        decorImage: (state) => {
            return state.themeConfig.decorImage;
        },
        gateLogo: (state) => {
            return state.themeConfig.gateLogo;
        },
        logoDesc: (state) => {
            return state.themeConfig.logoDescription;
        },
        splashImage: (state) => {
            return state.themeConfig.splashImage;
        },
        conferenceName: (state) => {
            return state.themeConfig.conferenceName;
        },
        displaySlogan: (state) => {
            return state.themeConfig.displaySlogan;
        },
        conferenceYear: (state) => {
            return state.themeConfig.conferenceYear;
        },
        apiConfig: (state) => {
            const option = state.themeConfig?.api || {};
            const env = process.env.NODE_ENV as string;
            let returnValue = {};

            if (option[env]) {
                returnValue = option[env];
            }

            return returnValue;
        },
        authConfig: (state) => {
            const option = state.themeConfig?.authConfig || {};
            const env = process.env.NODE_ENV as string;
            let returnValue = {};

            if (option[env]) {
                returnValue = option[env];
            }

            return returnValue;
        },
        awsConfig: (state) => state.themeConfig.awsConfig,
        user: (state) => state.user,
        isRegistered: (state) =>
            auth.isRegistered(
                state.userCredentials.idToken,
                state.themeConfig.conferenceId
            ),
        isAuthenticated: (state) => !!state.userCredentials.idToken,
        isAuthorized: (state, getters) =>
            getters.isAuthenticated && getters.isRegistered,
        isSessionExpired: (state) => {
            const currentTimeSeconds = new Date().getTime() / 1000;
            return currentTimeSeconds >= state.userCredentials.expires;
        },
        exhibitorSearchResults: (state) => state.exhibitorSearchResults,

        idToken: (state) => state.userCredentials.idToken,
        webfontSrc: (state) => state.themeConfig.webfontSrc,
        bannerAds: (state) => state.themeConfig.bannerAds
    },

    mutations: {
        setAuthWantsRoute(state, payload: string) {
            state.authWantsRoute = payload;
        },
        setIsFullScreen(state) {
            if (screenfull.isEnabled) {
                state.isFullScreen = screenfull.isFullscreen;
            } else {
                state.isFullScreen = false;
            }
        },

        setUser(state, payload = {}) {
            if (payload.idToken) {
                const idTokenData = auth.parseJWT(payload.idToken);
                // console.log(idTokenData, "idTokenData");
                state.user.id =
                    idTokenData["https://bespeake.io/attendeeId"] ||
                    idTokenData.sub;
                state.user.username = idTokenData.username || idTokenData.email;
                state.user.name = idTokenData.name;
                state.user.companyName =
                    idTokenData["https://bespeake.io/companyName"];
                state.user.email = idTokenData["email"];
                state.userCredentials.idToken = payload.idToken;
                state.userCredentials.accessToken = payload.accessToken;
                state.userCredentials.refreshToken = payload.refreshToken;
                state.userCredentials.expires = idTokenData.exp;
            } else {
                state.user = {
                    id: "",
                    email: "",
                    username: "",
                    name: "",
                    companyName: ""
                };
                state.userCredentials.idToken = "";
                state.userCredentials.accessToken = "";
                state.userCredentials.refreshToken = "";
                state.userCredentials.expires = 0;
            }
            localStorage.setItem("token", JSON.stringify(payload));
        },
        setRefreshing(state, payload) {
            state.userCredentials.isRefreshing = payload;
            // console.log(`set refreshing to ${payload}`);
        },
        setThemeConfig(state, payload) {
            state.themeConfig = payload;
        },
        setThemeConfigLoaded(state, payload) {
            state.themeConfigLoaded = payload;
        },
        setEnableHeader(state, payload) {
            state.enableHeader = payload;
        },
        setEnableFooter(state, payload) {
            state.enableFooter = payload;
        },
        setCSSvariables(state) {
            if (
                state.themeConfigLoaded &&
                state.themeConfig &&
                state.themeConfig.cssVariables
            ) {
                if (!state.themeConfig.cssVariables) return;

                Object.entries(state.themeConfig.cssVariables).forEach(
                    ([key, value]) => {
                        const documentElement = document.documentElement;
                        const x = value as string;
                        if (0 === key.indexOf("--") && x) {
                            documentElement.style.setProperty(key, x);
                        }
                    }
                );
            }
        },
        exhibitorSearchResults(state, payload) {
            state.exhibitorSearchResults = payload;
        }
    },
    actions: {
        toggleFullScreen: ({ commit }) => {
            Vue.prototype.MgToggleFullScreen().finally(() => {
                commit("setIsFullScreen");
            });
        },
        enableWrapper: ({ commit }) => {
            commit("setEnableHeader", true);
            commit("setEnableFooter", true);
        },
        disableWrapper: ({ commit }) => {
            commit("setEnableHeader", false);
            commit("setEnableFooter", false);
        },
        loginUser: async ({ commit, state, dispatch }, token) => {
            if (!token.idToken) return Promise.reject("no id token");
            try {
                const awsCredentials = await auth.getAWSIdentity(token.idToken);
                AWS.config.credentials = awsCredentials;
                AWS.config.update({ region: "us-east-1" });
            } catch (err) {
                return Promise.reject("Did not receive backend credentials.");
            }

            commit("setUser", token);

            dispatch("setupTalk");

            // establish Rollbar user
            Vue.prototype.$rollbar.configure({
                payload: {
                    person: {
                        id: state.user.id,
                        username: state.user.username
                    }
                }
            });
            const currentTimeSeconds = new Date().getTime() / 1000;
            // refresh 5 minutes before expiration
            let tokenRefreshTimer =
                state.userCredentials.expires - currentTimeSeconds - 300;
            // maximum refresh timer to not overflow the setTimeout. This is about 12 days.
            if (tokenRefreshTimer > 1073742) tokenRefreshTimer = 1073742;

            try {
                // TODO: Fix async code on setTimeout? I don't want to mess with this too much while it
                // still works. But should loop back to this soon.
                setTimeout(
                    async () =>
                        await dispatch("refreshToken").catch(() => {
                            commit("setUser", {});
                            commit("setAuthWantsRoute", "/gate");
                        }),
                    tokenRefreshTimer * 1000
                );
                // console.log("done done");

                const selectedEntrance = localStorage.getItem(
                    "selectedEntrance"
                );
                if (selectedEntrance) {
                    commit("setAuthWantsRoute", "/entrance");
                } else {
                    const storedPath = localStorage.getItem("storedPath");
                    if (storedPath) {
                        localStorage.removeItem("storedPath");
                        commit("setAuthWantsRoute", storedPath);
                    }
                }
            } catch (err) {
                console.error(err);
                return Promise.reject("Failed token refresh");
            }
        },
        refreshToken: async ({ state, getters, dispatch, commit }) => {
            if (state.userCredentials.isRefreshing)
                return Promise.reject("Already refreshing");
            commit("setRefreshing", true);
            try {
                if (!state.userCredentials.refreshToken) {
                    return Promise.reject("No refresh token");
                }
                const authConfig = getters.authConfig;
                const refreshToken = state.userCredentials.refreshToken;
                const token = await auth
                    .refreshToken(authConfig, refreshToken)
                    .catch((err) => {
                        Vue.prototype.$rollbar.warning(
                            `Failed to refresh token ${state.userCredentials.refreshToken}: ${err.message}`
                        );
                        return Promise.reject("Failed to refresh token");
                    });
                await dispatch("loginUser", token);
            } catch (err) {
                return Promise.reject("failed refresh");
            } finally {
                commit("setRefreshing", false);
            }
        },
        logout: async ({ commit, state, getters }) => {
            const token = state.userCredentials.idToken;
            const authConfig = getters.authConfig;
            commit("setUser", {});

            if (token && authConfig?.logoutURL) {
                window.location.href = authConfig.logoutURL;
            } else {
                commit("setAuthWantsRoute", "/gate");
            }
        }
    }
});
