import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store";
import eventHub from "@/event-hub";
import Gate from "../views/Gate.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "Home",
        component: () => {
            const options = store.getters.getPageOptions("home");
            const homeComponent = options?.componentVariance;
            let componentToUse: any = "";

            homeComponent.forEach((item: any) => {
                if (item.isActive) {
                    componentToUse = import(
                        /* webpackChunkName: "Home" */ `../components/home/${item.landingPage}`
                    );
                }
            });

            return componentToUse;

            // if(!homeComponent){
            //     return import(/* webpackChunkName: "Home" */ `../views/Home.vue`);
            // }else {
            //     return import(/* webpackChunkName: "Home" */ `../components/home/Image3DPage.vue`);
            // }
        },
        meta: {
            title: "Home Page"
        }
    },
    {
        path: "/home",
        redirect: {
            name: "Home"
        }
    },
    {
        path: "/gate",
        name: "Gate",
        component: () => {
            const options = store.getters.getPageOptions("gate");
            let gateComponent = options?.components;
            if (!gateComponent) {
                gateComponent = "Gate.vue";
            }
            return import(
                /* webpackChunkName: "Gate" */ `../views/${gateComponent}`
            );
        },
        meta: {
            title: "Portal to Meeting",
            noWrap: true
        },
        props: true,
        children: [
            {
                path: ":jwt",
                props: true,
                component: Gate
            }
        ]
    },
    {
        path: "/attendees",
        component: () =>
            import(
                /* webpackChunkName: "attendees" */ "../views/Attendees.vue"
            ),
        beforeEnter: (to, from, next) => {
            const activeRoute = store.getters.activeNavigation.find(
                (item: any) => "Attendees" === item.name
            );

            if (activeRoute?.active) {
                next();
            } else {
                next("/");
            }
        },
        children: [
            {
                name: "Attendees",
                path: "",
                component: () => {
                    const options = store.getters.getPageOptions("attendees");
                    let searchComponent = options?.components?.search;

                    if (!searchComponent) {
                        searchComponent = "AttendeeSearch.vue";
                    }

                    return import(
                        /* webpackChunkName: "attendees" */ `../components/attendee-search/${searchComponent}`
                    );
                },
                meta: {
                    title: "Attendees"
                }
            },
            {
                name: "AttendeeSearchResults",
                path: "results",
                component: () =>
                    import(
                        /* webpackChunkName: "attendees" */ "../components/attendee-search/AttendeeSearchResults.vue"
                    ),
                meta: {
                    title: "Attendee Search Results"
                }
            },
            {
                path: "attendee-profile/",
                name: "MyProfile",
                props: true,
                component: () => {
                    return import(
                        /* webpackChunkName: "attendeeProfile" */ "../components/attendee-profile/AttendeeProfile.vue"
                    );
                },
                meta: {
                    title: "My Profile"
                }
            },
            {
                path: "attendee-profile/:id",
                name: "attendeeProfile",
                props: true,
                component: () =>
                    import(
                        /* webpackChunkName: "attendeeProfile" */ "../components/attendee-profile/AttendeeProfile.vue"
                    ),
                meta: {
                    title: "Attendee Profile Page"
                }
            },
            {
                path: "company-listing/:id",
                name: "companyListing",
                props: true,
                component: () =>
                    import(
                        /* webpackChunkName: "attendeeProfile" */ "../components/attendee-search/AttendeeCompanyListing.vue"
                    ),
                meta: {
                    title: "Company Attendee Listing"
                }
            },
            {
                name: "AttendeeList",
                path: "list",
                component: () =>
                    import(
                        /* webpackChunkName: "attendees" */ "../components/attendee-search/AttendeeList.vue"
                    ),
                meta: {
                    title: "Attendee List"
                }
            }
        ]
    },
    {
        path: "/sessions",
        name: "Sessions",
        meta: {
            title: "Sessions"
        },
        component: () =>
            import(
                /* webpackChunkName: "sessions" */ `../views/SessionsView.vue`
            ),
        // this will check if this route is active for navigation
        beforeEnter: (to, from, next) => {
            const activeRoute = store.getters.activeNavigation.find(
                (item: any) => "Sessions" === item.name
            );

            if (activeRoute?.active) {
                next();
            } else {
                next("/");
            }
        }
    },
    {
        path: "/sessions/:id",
        name: "Session",
        component: () =>
            import(/* webpackChunkName: "session" */ "../views/LiveStream.vue")
    },
    {
        path: "/sessions/poll-results/:id",
        name: "Session Poll Results",
        meta: {
            noWrap: true
        },
        component: () =>
            import(/* webpackChunkName: "session" */ "../views/PollResults.vue")
    },
    {
        path: "/meeting/:id",
        name: "MeetingView",
        meta: { noWrap: true },
        component: () => {
            return import(
                /* webpackChunkName: "meeting" */ "../views/Meeting.vue"
            );
        }
    },
    {
        path: "/meeting/:id/developer",
        name: "MeetingDeveloper",
        meta: { noWrap: true },
        component: () =>
            import(/* webpackChunkName: "meeting" */ "../views/Meeting.vue")
    },
    {
        path: "/tradeshow",
        component: () =>
            import(
                /* webpackChunkName: "Tradeshow" */ "../views/Tradeshow.vue"
            ),
        beforeEnter: (to, from, next) => {
            const activeRoute = store.getters.activeNavigation.find(
                (item: any) => "Tradeshow" === item.name
            );

            if (activeRoute?.active) {
                next();
            } else {
                next("/");
            }
        },
        children: [
            {
                name: "Tradeshow",
                path: "",
                component: () => {
                    const options = store.getters.getPageOptions("tradeshow");
                    let componentToUse = options?.components?.search;

                    if (!componentToUse) {
                        componentToUse = "search/ExhibitorSearch.vue";
                    }
                    return import(
                        /* webpackChunkName: "tradeshow" */ `../components/tradeshow/${componentToUse}`
                    );
                },
                meta: {
                    title: "Tradeshow"
                }
            },
            {
                path: "results",
                name: "ExhibitorSearchResults",
                component: () =>
                    import(
                        /* webpackChunkName: "tradeshow" */ "../components/tradeshow/search/ExhibitorSearchResults.vue"
                    ),
                meta: {
                    title: "Company Search Result"
                }
            },
            {
                path: "exhibitor-details/:id",
                name: "ExhibitorDetails",
                props: true,
                component: () =>
                    import(
                        /* webpackChunkName: "tradeshow" */ "../components/exhibitor-profile/ExhibitorExpandedProfile.vue"
                    ),
                meta: {
                    title: "Company profile page"
                }
            }
        ]
    },
    {
        name: "Resources",
        path: "",
        component: () =>
            import(
                /* webpackChunkName: "Resources" */ "../views/ResourcesView.vue"
            ),
        // this will check if this route is active for navigation
        beforeEnter: (to, from, next) => {
            const activeRoute = store.getters.activeNavigation.find(
                (item: any) => "Resources" === item.name
            );

            if (activeRoute?.active) {
                next();
            } else {
                next("/");
            }
        },
        children: [
            {
                name: "resourceComponent",
                path: "/resources",
                component: () => {
                    const options = store.getters.getPageOptions("resources");
                    let componentToUse = options?.componentss;

                    if (!componentToUse) {
                        componentToUse = "Resources.vue";
                    }
                    return import(
                        /* webpackChunkName: "c" */ `../components/Resources/${componentToUse}`
                    );
                },
                meta: {
                    title: "Resources"
                }
            }
        ]
    },
    {
        name: "Presenters",
        path: "",
        component: () =>
            import(
                /* webpackChunkName: "Resources" */ "../views/PresentersView.vue"
            ),
        // this will check if this route is active for navigation
        beforeEnter: (to, from, next) => {
            const activeRoute = store.getters.activeNavigation.find(
                (item: any) => "Presenters" === item.name
            );

            if (activeRoute?.active) {
                next();
            } else {
                next("/");
            }
        },
        children: [
            {
                name: "PresentersComponent",
                path: "/presenters",
                component: () => {
                    const options = store.getters.getPageOptions("presenters");
                    let componentToUse = options?.componentss;

                    if (!componentToUse) {
                        componentToUse = "Presenters.vue";
                    }
                    return import(
                        /* webpackChunkName: "c" */ `../components/Presenters/${componentToUse}`
                    );
                },
                meta: {
                    title: "Presenters"
                }
            }
        ]
    },
    {
        path: "/message-center",
        name: "Message-Center",
        meta: {
            title: "Message Center"
        },
        component: () =>
            import(
                /* webpackChunkName: "message-center" */ "../views/MessageCenterView.vue"
            ),
        // this will check if this route is active for navigation
        beforeEnter: (to, from, next) => {
            const activeRoute = store.getters.activeNavigation.find(
                (item: any) => "Message-Center" === item.name
            );

            if (activeRoute?.active) {
                next();
            } else {
                next("/");
            }
        }
    },
    {
        path: "/request-meeting",
        name: "CreateActivity",
        meta: {
            title: "Request A Meeting"
        },
        component: () => {
            return import(
                /* webpackChunkName: "message-center" */ "../views/CreateActivity.vue"
            );
        },
        beforeEnter: (to, from, next) => {
            const optionChecker = store.getters.isPageOptionActiveInEnv;
            const isActive = optionChecker("createActivity", "isActive");

            if (isActive) {
                next();
            } else {
                next("/");
            }
        }
    },
    {
        path: "/admin",
        name: "AdminPanel",
        component: () =>
            import(
                /* webpackChunkName: "create-exhibitor" */ "../views/AdminHub.vue"
            ),
        beforeEnter: (to, from, next) => {
            const isUserAllowed = store.getters;
            setTimeout(() => {
                const isSuperUser = isUserAllowed.userInfo.superuser;
                if (isSuperUser) {
                    next();
                } else {
                    next("/401");
                }
            }, 2000);
        },
        children: [
            // {
            //     path: "create-exhibitor",
            //     name: "CreateExhibitor",
            //     component: () =>
            //         import(
            //             /* webpackChunkName: "create-exhibitor" */ "../components/admin/profiles/CreateExhibitor.vue"
            //         )
            // },
            // {
            //     path: "create-attendee",
            //     name: "CreateAttendee",
            //     component: () =>
            //         import(
            //             /* webpackChunkName: "create-attendee" */ "../components/admin/profiles/CreateAttendee.vue"
            //         )
            // },
            {
                path: "schedule",
                name: "AdminSchedule",
                meta: {
                    title: "Admin Schedule",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Admin schedule" */ "../components/admin/schedule/AdminScheduleView.vue"
                    )
            },
            {
                path: "sessions",
                name: "AdminSession",
                meta: {
                    title: "Admin session",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Admin schedule" */ "../components/admin/sessions/AdminSessionsView.vue"
                    )
            },
            {
                path: "editSession",
                name: "EditSession",
                props: true,
                meta: {
                    title: "Edit Session",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Admin schedule" */ "../components/admin/schedule/EditSession.vue"
                    )
            },
            {
                path: "evaluations",
                name: "AdminEvaluations",
                props: true,
                meta: {
                    title: "View Evaluations",
                    noWrap: true
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Admin schedule" */ "../components/admin/evaluations/AdminEvalsView.vue"
                    )
            }
        ],
        meta: {
            title: "Admin Panel",
            noWrap: true
        }
    },
    {
        name: "Schedule",
        path: "",
        component: () =>
            import(
                /* webpackChunkName: "Schedule" */ "../views/ScheduleView.vue"
            ),
        // this will check if this route is active for navigation
        beforeEnter: (to, from, next) => {
            const activeRoute = store.getters.activeNavigation.find(
                (item: any) => "Schedule" === item.name
            );

            if (activeRoute?.active) {
                next();
            } else {
                next("/");
            }
        },
        children: [
            {
                path: "/schedule",
                name: "scheduleComponent",
                component: () => {
                    const options = store.getters.getPageOptions("schedule");
                    let componentToUse = options?.components?.landingPage;

                    if (!componentToUse) {
                        componentToUse = "schedule.vue";
                    }
                    return import(
                        /* webpackChunkName: "create-attendee" */ `../components/schedule/${componentToUse}`
                    );
                },
                meta: {
                    title: "Schedule"
                }
            }
        ]
    },
    {
        path: "/help",
        name: "Help",
        meta: {
            title: "Help"
        },
        component: () =>
            import(
                /* webpackChunkName: "create-attendee" */ "../views/HelpPage.vue"
            ),
        beforeEnter: (to, from, next) => {
            const activeRoute = store.getters.activeNavigation.find(
                (item: any) => "Help" === item.name
            );

            if (activeRoute?.active) {
                next();
            } else {
                next("/");
            }
        }
    },
    {
        path: "/live-stream/:id",
        name: "live stream",
        component: () =>
            import(
                /* webpackChunkName: "live-stream" */ "../views/LiveStream.vue"
            )
    },
    {
        path: "/mg-live",
        name: "StreamVideoLive",
        meta: {
            title: "Live Stream",
            noWrap: true
        },
        component: () => {
            const options = store.getters.getPageOptions("StreamVideoLive");
            let componentToUse = options.components;

            if (!componentToUse) {
                componentToUse = "StreamVideoLive.vue";
            }

            return import(
                /* webpackChunkName: "live-stream" */ `../views/${componentToUse}`
            );
        }
    },
    {
        path: "/entrance",
        name: "Entrance",
        meta: {
            noWrap: true,
            title: "Embarking on a journey to your meeting..."
        },
        component: () =>
            import(
                /* webpackChunkName: "transitions" */ "../views/EntranceAnimation.vue"
            )
    },
    {
        path: "/posters",
        name: "posterview",
        component: () =>
            import(
                /* webpackChunkName: "posters" */ "../views/PostersView.vue"
            ),
        beforeEnter: (to, from, next) => {
            const activeRoute = store.getters.activeNavigation.find(
                (item: any) => "posterview" === item.name
            );

            if (activeRoute?.active) {
                next();
            } else {
                next("/");
            }
        },
        children: [
            {
                name: "PosterGallery",
                path: "/posters",
                component: () =>
                    import(
                        /* webpackChunkName: "PosterGallery" */ "../components/sessions/posters/PostersGridDisplay.vue"
                    )
            },
            {
                name: "PosterDetails",
                path: "poster-details/:id",
                props: true,
                component: () =>
                    import(
                        /* webpackChunkName: "postersDetails" */ "../components/sessions/posters/PosterDetails.vue"
                    )
            },
            {
                name: "ModifyPoster",
                path: "EditPoster",
                props: true,
                component: () =>
                    import(
                        /* webpackChunkName: "CreatePoster" */ "../components/sessions/posters/EditPoster.vue"
                    )
            }
        ]
    },
    {
        path: "/networking/:id",
        name: "NetworkingLanding",
        props: true,
        meta: {
            title: "Network landing page"
        },
        component: () =>
            import(
                /* webpackChunkName: "Networking" */ "../components/networking/LandingPage.vue"
            )
    },
    {
        path: "/wearecalled",
        name: "wearecalled",
        beforeEnter() {
            const activeRoute = store.getters.activeNavigation.find(
                (item: any) => {
                    if ("wearecalled" === item.name) {
                        return item.link;
                    }
                }
            );
            location.href = activeRoute.link;
        }
    },
    {
        path: "/awards",
        name: "awards",
        component: () =>
            import(
                /* webpackChunkName: "Static-Pages" */ "../components/static-pages/Awards.vue"
            ),
        beforeEnter: (to, from, next) => {
            const activeRoute = store.getters.activeNavigation.find(
                (item: any) => "awards" === item.name
            );

            if (activeRoute?.active) {
                next();
            } else {
                next("/");
            }
        }
    },
    {
        path: "/401",
        name: "NotAuthorized",
        meta: {
            noWrap: true,
            title: "Not Authorized"
        },
        component: () =>
            import(
                /* webpackChunkName: "NotAuthorized" */ "../views/NotAuthorized.vue"
            )
    },
    {
        path: "*",
        name: "NotFound",
        meta: {
            title: "Not Found"
        },
        component: () =>
            import(/* webpackChunkName: "NotFound" */ "../views/NotFound.vue")
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.query?.noScroll === "true") {
            return null;
        }

        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});

router.beforeEach(async (to, from, next) => {
    const toRoute = to.matched.find((record) => to.name === record.name);
    const isSiteActive = store.getters.isSiteActive;
    const isGateRoute = to.path.startsWith("/gate");
    const isAuthorized = store.getters.isAuthorized;
    const allowedMeetingDateTimes =
        store.state.settingsVuexModule.allowedMeetingDateTimes;

    if (0 === allowedMeetingDateTimes.length && isAuthorized) {
        eventHub.$emit("request-meeting-settings");
    }

    // update page title
    document.title =
        to?.meta?.title || store.getters.conferenceName || "BeSpeake";

    // handle app wrapper display
    if (toRoute?.meta?.noWrap) {
        store.dispatch("disableWrapper");
    } else {
        store.dispatch("enableWrapper");
    }

    // handle authentication
    if (!isSiteActive && !isGateRoute) {
        return store.dispatch("logout");
    } else if (
        store.state.themeConfig.forceAuth &&
        !store.getters.isAuthorized &&
        !isGateRoute
    ) {
        localStorage.setItem("storedPath", to.path);
        return next({ path: "/gate" });
    }

    // this is last
    return next();
});

export default router;
