import Vue from "vue";
import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import { getApiClient } from "@/services/api";
import { config } from "vuex-module-decorators";
import {
    compareAsc,
    format,
    parseISO,
    isValid,
    isDate,
    eachDayOfInterval
} from "date-fns";

const endpoint = `settings`;

// Set rawError to true by default on all @Action decorators
config.rawError = true;

/**
 * NOTE: not using dynamic module - this module is being used in `App.vue` where for some reason dynamic modules throw an error.
 */
@Module
export default class SettingsModule extends VuexModule {
    allowedMeetingDates: Array<string> = [];
    allowedMeetingDateTimes: Array<Array<string>> = [];
    sessionEarlyEntryMinutes = 0;
    appointmentEarlyEntryMinutes = 0;
    hostAppointmentEarlyEntryMinutes = 0;
    maxInvitesAttendee = 0;
    maxInvitesByTier = {};
    speakeasyExtraMinutes = 5;
    exhibitorThresholdAddToProfile = 9999;

    @Mutation
    setSessionEarlyEntryMinutes(payload: number) {
        const value = Number(payload);
        if (-1 < value) {
            this.sessionEarlyEntryMinutes = value;
        } else {
            console.warn("sessionEarlyEntryMinutes is not a valid value");
        }
    }

    @Mutation
    setAppointmentEarlyEntryMinutes(payload: number) {
        const value = Number(payload);
        if (-1 < value) {
            this.appointmentEarlyEntryMinutes = value;
        } else {
            console.warn("appointmentEarlyEntryMinutes is not a valid value");
        }
    }

    @Mutation
    setHostAppointmentEarlyEntryMinutes(payload: number) {
        const value = Number(payload);
        if (-1 < value) {
            this.hostAppointmentEarlyEntryMinutes = value;
        } else {
            console.warn(
                "hostAppointmentEarlyEntryMinutes is not a valid value"
            );
        }
    }

    @Mutation
    setMaxInvitesAttendee(payload: number) {
        const value = Number(payload);
        if (-1 < value) {
            this.maxInvitesAttendee = value;
        } else {
            console.warn("maxInvitesAttendee is not a valid value");
        }
    }

    @Mutation
    setExhibitorThresholdAddToProfile(payload: number) {
        const value = Number(payload);
        if (-1 < value) {
            this.exhibitorThresholdAddToProfile = value;
        } else {
            console.warn("exhibitorThresholdAddToProfile is not a valid value");
        }
    }

    @Mutation
    setMaxInvitesByTier(payload: Record<string, number>) {
        this.maxInvitesByTier = payload;
    }

    @Mutation
    setAllowedMeetingDateTimes(payload: Array<Array<string>> = []) {
        const updateValue = payload.filter((isoSet) => {
            const hasInvalidString = isoSet.find((iso) => {
                const isoToDateObj = parseISO(iso);
                return !isValid(isoToDateObj);
            });

            if (isoSet.length && !hasInvalidString) {
                return isoSet;
            }
        });

        this.allowedMeetingDateTimes = updateValue;
    }

    @Mutation
    setAllowedMeetingDates(payload: Array<string> = []) {
        this.allowedMeetingDates = payload;
    }

    @Mutation
    setSpeakeasyExtraMinutes(payload: number) {
        const value = Number(payload);
        if (-1 < value) {
            this.speakeasyExtraMinutes = value;
        } else {
            console.warn("speakeasyExtraMinutes is not a valid value");
        }
    }

    @Action({})
    async getAndSetMeetingSettings() {
        this.context.dispatch("getMeetingSettings").then((data) => {
            const allowedTimes = data["SCHEDULING_ALLOWED_TIMES"];
            const sessionEarlyMins = data["SESSION_EARLY_ENTRY_MINUTES"];
            const appointmentEarlyMins =
                data["APPOINTMENT_EARLY_ENTRY_MINUTES"];
            const hostAppointmentEarlyMins =
                data["HOST_APPOINTMENT_EARLY_ENTRY_MINUTES"];
            const speakeasyExtraMinutes = data["speakeasyExtraMinutes"];

            // create activity settings
            const createActivitySettings = data.createActivity || {};
            const maxInvitesAttendee =
                createActivitySettings["MAX_INVITES_ATTENDEE"];
            const maxInvitesByTier =
                createActivitySettings["MAX_INVITES_BY_TIER"];
            const exhibitorThresholdAddToProfile =
                createActivitySettings["exhibitorThresholdAddToProfile"];

            this.context.commit(
                "setSessionEarlyEntryMinutes",
                sessionEarlyMins
            );
            this.context.commit(
                "setAppointmentEarlyEntryMinutes",
                appointmentEarlyMins
            );
            this.context.commit(
                "setHostAppointmentEarlyEntryMinutes",
                hostAppointmentEarlyMins
            );
            this.context.commit(
                "setSpeakeasyExtraMinutes",
                speakeasyExtraMinutes
            );

            this.context.commit("setMaxInvitesAttendee", maxInvitesAttendee);
            this.context.commit("setMaxInvitesByTier", maxInvitesByTier);
            this.context.commit(
                "setExhibitorThresholdAddToProfile",
                exhibitorThresholdAddToProfile
            );

            if (Array.isArray(allowedTimes)) {
                this.context.dispatch(
                    "handleAllowedMeetingDateTimes",
                    allowedTimes
                );
            }
        });
    }

    @Action({})
    async getMeetingSettings() {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`/${endpoint}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({})
    handleAllowedMeetingDateTimes(payload: Array<Array<string>> = []) {
        let allowedDates = [];
        let allowedDateStrings = [];

        // first
        this.context.commit("setAllowedMeetingDateTimes", payload);

        // then
        allowedDates = this.allowedMeetingDateTimes.flatMap((dateSet) => {
            let returnValue: Array<Date | number> = [0];
            const firstIsoString = dateSet[0];
            const secondIsoString = dateSet[1];
            let firstDate: Date | number = 0;
            let secondDate: Date | number = 0;

            if (Vue.prototype.MgIsValidISOString(firstIsoString)) {
                firstDate = parseISO(firstIsoString);
            }

            if (Vue.prototype.MgIsValidISOString(secondIsoString)) {
                secondDate = parseISO(secondIsoString);
            }

            if (Boolean(firstDate) && Boolean(secondDate)) {
                returnValue = eachDayOfInterval({
                    start: firstDate,
                    end: secondDate
                });
            } else if (firstDate) {
                returnValue = [firstDate];
            }

            return returnValue;
        });
        allowedDates.sort(compareAsc);
        allowedDateStrings = allowedDates
            .map((date) => {
                if (isDate(date)) {
                    return format(date, "yyyy-MM-dd");
                } else {
                    return null;
                }
            })
            .filter((item) => item);

        this.context.commit("setAllowedMeetingDates", allowedDateStrings);
    }
}
