<script>
// TODO: Part 1 - import apiSvc from "@/services/api"; does not work here
import axios from "axios";
import { mapGetters } from "vuex";
import { addSeconds } from "date-fns";

const ONE_SECOND = 1000;

export default {
    name: "HandleServerTime",
    data() {
        return {
            serverTimeInterval: null,
            refetchInterval: null,
            currentTimeISO: "",
            serverTimeIntervalFunctionHasExecuted: false
        };
    },
    computed: {
        ...mapGetters(["apiConfig", "idToken"]),
        currentTimeIsValidISO() {
            const iso = this.currentTimeISO;
            return this.MgIsValidISOString(iso);
        }
    },
    created() {
        /**
         * Please note:
            1 - setting `MgServerTime` on window not storing in Vuex
                this is because we dont want to call a mutation every second,
                doing so would pollute the mutations list in vue devtools.

            2 - initialize `window.MgServerTime` with visitors local time
                this is in case other code tries to use `window.MgServerTime` before `getServerTime` completes

            3 - TODO: possibly dont allow the app to render if the `/server-time` endpoint fails,
                this is a double edge sword. 
                a. On the one hand it will ensure user's UI is synced with all attendees.
                b. On the other hand it could cause the app to be completely inaccessible if the `/server-time`
                endpoint fails while other endpoints continue to function.

                Each call made to `/server-time` matters. 
                We can not rely on one intial value from `/server-time` to guarantee
                we'll be up to date with server time without consulting `/server-time` again;
                See NOTE:`currentTimeISO` below.

            Perhaps we DONT do `3` and keep as is BUT shut down areas of app that DO depend on sever time,
            e.g. schedule and speakeasies. Need to discuss.

            + Testing with the chrome devtools network 'slow 3g' option,
              `/server-time` takes about ~3 seconds to complete.
        */
        window.MgServerTime = new Date();

        // request time from server
        this.initServerTime();

        // start updating `MgServerTime` every second
        // will use local time until `initServerTime` completes successfully
        this.handleServerTimeInterval();

        // NOTE:`currentTimeISO`
        // window.setInterval does not consistently run every X seconds depending on..
        // ..acitivity in javascripts main thread.
        // It is possible for the latest `currentTimeISO` we've used to fall out of sync with true server time
        // so we will refresh `currentTimeISO` on an interval
        this.handleRefetchInterval();
    },
    beforeDestroy() {
        window.clearInterval(this.serverTimeInterval);
        window.clearInterval(this.refetchInterval);
    },
    methods: {
        handleRefetchInterval() {
            window.clearInterval(this.refetchInterval);
            this.refetchInterval = window.setInterval(
                this.initServerTime,
                ONE_SECOND * 30
            );
        },
        handleServerTimeInterval() {
            window.clearInterval(this.serverTimeInterval);

            this.serverTimeInterval = window.setInterval(() => {
                this.serverTimeIntervalFunctionHasExecuted = true;
                this.updateServerTimeVariable();
            }, ONE_SECOND);
        },
        updateServerTimeVariable() {
            // `updateServerTimeVariable` needs to run on a fixed interval;
            // do NOT interrupt that fixed interval.

            if (this.currentTimeIsValidISO) {
                window.MgServerTime = new Date(this.currentTimeISO);
                // Now reset so we can continue to `addSeconds` next time this function is called.
                this.currentTimeISO = null;
            } else {
                window.MgServerTime = addSeconds(window.MgServerTime, 1);
            }
        },
        initServerTime() {
            this.getServerTime().catch((error) => {
                console.error(error);
            });
        },
        getServerTime() {
            return new Promise((resolve, reject) => {
                // TODO: Part 2 - this should come from API service
                const baseURL = this.apiConfig?.baseUrl;
                const apiClient = axios.create({
                    baseURL,
                    withCredentials: false,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                });

                apiClient
                    .get(`/server-time`, {
                        headers: {
                            Authorization: `bearer ${this.idToken}`
                        }
                    })
                    .then((response) => {
                        if (response.data && response.data.currentTimeISO) {
                            // This is the only place an iso value should be set for `currentTimeISO`.
                            this.currentTimeISO = response.data.currentTimeISO;

                            // this is the only case where `updateServerTimeVariable` can be called
                            // outside of `serverTimeInterval`;
                            // if `updateServerTimeVariable` is called when the `serverTimeInterval` function
                            // has started executing we will end up with a pronounced time discrepency across users
                            // e.g. `updateServerTimeVariable` needs to run on a fixed interval;
                            // we can not interrupt that fixed interval.
                            if (!this.serverTimeIntervalFunctionHasExecuted) {
                                this.updateServerTimeVariable();
                            }
                        }

                        return resolve();
                    })
                    .catch((error) => {
                        return reject(error);
                    });
            });
        }
    },
    render(h) {
        return h(null);
    }
};
</script>
