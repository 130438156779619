import axios from "axios";
import AWS from "aws-sdk/global";
import TextEncodingPolyfill from "text-encoding";

interface AuthConfig {
    loginURL: string;
    logoutURL: string;
    tokenURL: string;
    grantType: string;
    clientId: string;
    providerId: string;
}

interface AuthResponse {
    idToken: string;
    refreshToken: string;
    accessToken: string;
}

/**
 * OIDC Authentication with MatrixMaxx
 *   Authorization Grant w/ PKCE
 *   Scope: openid
 *   challenge: sha256/base 64 url encoded
 *
 *   TODO: Implement refresh tokens
 *         Store/use access tokens
 *         Claims on id
 */

// HELPERS
/**
 * Generates a cryptographically secure random string
 * of variable length.
 *
 * The returned string is also url-safe.
 *
 * @param {Number} length the length of the random string.
 * @returns {String}
 */
function randomString(length: number) {
    const validChars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let array = new Uint8Array(length);
    window.crypto.getRandomValues(array);
    array = array.map((x) => validChars.charCodeAt(x % validChars.length));
    return String.fromCharCode(...array);
}

/**
 * Takes a base64 encoded string and returns a url encoded string
 * by replacing the characters + and / with -, _ respectively,
 * and removing the = (fill) character.
 *
 * @param {String} input base64 encoded string.
 * @returns {String}
 */
function urlEncodeB64(str: string) {
    return str
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "");
}

/**
 * Takes an ArrayBuffer and convert it to Base64 url encoded string.
 * @param {ArrayBuffer} input
 * @returns {String}
 */
function bufferToBase64UrlEncoded(input: ArrayBuffer) {
    console.log("input is");
    console.log(input);
    const bytes = new Uint8Array(input);
    return urlEncodeB64(window.btoa(String.fromCharCode(...bytes)));
}

/**
 * Returns the sha256 digst of a given message.
 * This function is async.
 *
 * @param {String} message
 * @returns {Promise<ArrayBuffer>}
 */
function sha256(message: string) {
    let encoder;
    if (typeof TextEncoder !== "function") {
        encoder = new TextEncodingPolyfill.TextEncoder();
    } else {
        encoder = new TextEncoder();
    }
    const data = encoder.encode(message);
    return window.crypto.subtle.digest("SHA-256", data);
}

// END HELPERS

const auth = {
    async getAuthToken(authConfig: AuthConfig) {
        const verifier = randomString(45);
        const challenge = await sha256(verifier).then(bufferToBase64UrlEncoded);
        localStorage.setItem("verifier", verifier);
        const params = new URLSearchParams();
        params.append("response_type", "code");
        params.append("client_id", authConfig.clientId);
        params.append("code_challenge", challenge);
        params.append("code_challenge_method", "S256");
        params.append("scope", "openid profile email user_metadata");
        params.append("state", "");
        params.append("redirect_uri", `${window.location.origin}/gate`);
        window.location.href = `${authConfig.loginURL}?${params.toString()}`;
    },
    async getAccessToken(
        authConfig: AuthConfig,
        code: string
    ): Promise<AuthResponse> {
        const verifier = localStorage.getItem("verifier") || "";
        const params = new URLSearchParams();
        params.append("grant_type", "authorization_code");
        params.append("code", code);
        params.append("client_id", authConfig.clientId);
        params.append("code_verifier", verifier);
        params.append("scope", "openid profile email user_metadata");
        params.append("state", "");
        params.append("redirect_uri", `${window.location.origin}/gate`);
        return axios.post(authConfig.tokenURL, params).then((response) => {
            // console.log(response);
            localStorage.removeItem("verifier");
            return {
                idToken: response.data.id_token,
                accessToken: response.data.access_token,
                refreshToken: response.data.refresh_token
            };
        });
    },
    async refreshToken(authConfig: AuthConfig, refreshToken: string) {
        const params = new URLSearchParams();
        params.append("grant_type", "refresh_token");
        params.append("refresh_token", refreshToken);
        params.append("client_id", authConfig.clientId);
        return axios.post(authConfig.tokenURL, params).then((response) => {
            return {
                idToken: response.data.id_token,
                accessToken: response.data.access_token,
                refreshToken: response.data.refresh_token
            };
        });
    },
    async getAWSIdentity(idToken: string) {
        return new AWS.WebIdentityCredentials({
            RoleArn:
                "arn:aws:iam::543034962478:role/service-role/GetExhibitor-role-xi22e4ix",
            WebIdentityToken: idToken, // token from identity service
            RoleSessionName: "vmp" // optional name, defaults to web-identity
        });
    },
    parseJWT(token: string) {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split("")
                .map(function(c) {
                    return (
                        "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
                    );
                })
                .join("")
        );

        return JSON.parse(jsonPayload);
    },
    isRegistered(token: string, conferenceId: string) {
        if (token) {
            const parsed = this.parseJWT(token);
            if (`https://bespeake.io/vmp_${conferenceId}` in parsed) {
                return parsed[`https://bespeake.io/vmp_${conferenceId}`];
            }
            if ('https://bespeake.io/registrations' in parsed) {
                const registrationList = parsed['https://bespeake.io/registrations'].split(',')
                return registrationList.includes(conferenceId)
            }
        }
        return false;
    }
};
export default auth;
