import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { getApiClient } from "@/services/api";
import { SessionEvaluation } from "@/types/interfaces";
import Store from "../index";

// This module uses multiple endpoints that are related.
const evalEndpoint = "sessionEvals";
const videoEndpoint = "video-view-count";

interface ActivateSessionPayload {
    sessionId: string;
    sessionName: string;
    startTime?: string;
}

interface ShouldShowEvalPayload {
    sessionId: string;
    minutesBeforeSessionEval?: number;
    startTime?: string;
}

interface VideoTimePayload {
    sessionId: string;
    minutesBeforeSessionEval?: number;
    startTime: string;
}

@Module({
    dynamic: true,
    store: Store,
    name: "SessionEvalModule",
    namespaced: true
})
export default class SessionEvalModule extends VuexModule {
    sessionId = "";
    myResponses: Array<SessionEvaluation> = [];
    sessionName = "";

    get isActive() {
        return this.sessionId !== "";
    }

    get myEvals() {
        return this.myResponses;
    }

    get activeSessionData() {
        return this.context.rootState.getSessionData.singleSession;
    }

    get defaultMinutes() {
        const options = this.context.rootGetters.getPageOptions("sessionEvals");

        return options && options.defaultMinutesBeforeEval
            ? options.defaultMinutesBeforeEval
            : 5;
    }

    @Mutation
    public setSessionId(data: string) {
        this.sessionId = data;
    }

    @Mutation
    public setSessionName(data: string) {
        this.sessionName = data;
    }

    @Mutation
    public setMyResponses(data: Array<SessionEvaluation>) {
        this.myResponses.splice(0, this.myResponses.length);
        data.forEach((item) => this.myResponses.push(item));
    }

    @Action({})
    async hasViewedEnough(payload: ShouldShowEvalPayload) {
        const howMuchTime = payload.minutesBeforeSessionEval
            ? payload.minutesBeforeSessionEval
            : this.activeSessionData &&
              this.activeSessionData.minutesBeforeSessionEval
            ? this.activeSessionData.minutesBeforeSessionEval
            : this.context.getters.defaultMinutes;

        const startTime = payload.startTime
            ? payload.startTime
            : this.activeSessionData.startTime;
        const videoCount = await this.context.dispatch("getVideoViewCount", {
            sessionId: payload.sessionId,
            startTime: startTime
        });

        return videoCount >= howMuchTime;
    }

    @Action({})
    async shouldShowEval(payload: ShouldShowEvalPayload) {
        const pageOptions = this.context.rootGetters.getPageOptions(
            "sessionEvals"
        );
        const isEnabled =
            pageOptions && "enabled" in pageOptions
                ? pageOptions.enabled
                : true;

        if (!isEnabled) {
            return false;
        }

        const hasViewedEnough = await this.context.dispatch(
            "hasViewedEnough",
            payload
        );

        const thisSessionEvals = this.context.getters.myEvals.filter(
            (resp: SessionEvaluation) => resp.sessionId === payload.sessionId
        );
        const hasEval = Boolean(thisSessionEvals.length);

        return !hasEval && hasViewedEnough;
    }

    @Action({})
    async activateSessionEval(payload: ActivateSessionPayload) {
        const shouldShow = await this.context.dispatch("shouldShowEval", {
            sessionId: payload.sessionId
        });

        if (shouldShow) {
            this.context.commit("setSessionId", payload.sessionId);
            this.context.commit("setSessionName", payload.sessionName);
        } else {
            this.context.dispatch("deactivateSessionEval");
        }
    }

    @Action({})
    deactivateSessionEval() {
        this.context.commit("setSessionId", "");
        this.context.commit("setSessionName", "");
    }

    @Action({ commit: "setMyResponses" })
    async getMyResponses() {
        const token = this.context.rootGetters.idToken;
        const userId = Store.getters.user.id;

        return new Promise((resolve, reject) => {
            getApiClient()
                .post(
                    `/${evalEndpoint}`,
                    {
                        userId: userId
                    },
                    {
                        headers: {
                            Authorization: `bearer ${token}`
                        }
                    }
                )
                .then((response) => {
                    let items: Array<SessionEvaluation> = [];

                    if (response && Array.isArray(response.data)) {
                        response.data.forEach((item) => {
                            items.push(item);
                        });
                    } else {
                        items = [];
                    }

                    return resolve(items);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({})
    async getVideoViewCount(payload: VideoTimePayload) {
        const token = this.context.rootGetters.idToken;
        const userId = Store.getters.user.id;

        return new Promise((resolve, reject) => {
            getApiClient()
                .post(
                    `/${videoEndpoint}`,
                    {
                        userId: userId,
                        sessionId: payload.sessionId,
                        afterTime: payload.startTime
                    },
                    {
                        headers: {
                            Authorization: `bearer ${token}`
                        }
                    }
                )
                .then((response) => {
                    let count = 0;

                    if (response && response.data) {
                        count = response.data;
                    }

                    return resolve(count);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }
}
